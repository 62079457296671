import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  currentMessage = new BehaviorSubject(null);
  token: string;

  
  constructor(private angularFireMessaging: AngularFireMessaging) {
    // this.angularFireMessaging.messages.subscribe(
    //   (_message) => {
    //     _message.onMessage = _message.onMessage.bind(_message);
    //     _message.onTokenRefresh = _message.onTokenRefresh.bind(_message);
    //   }
    // )
   }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.token = token;
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
}
