import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatRadioModule } from '@angular/material/radio';
// import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AsyncPipe, DatePipe } from '@angular/common';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AppCommonModule } from 'src/app/core/shared/app-common/app-common.module';

import { AppComponent } from './app.component';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { jwtOptionsFactory } from './core/helpers/tokenGetter.helper';
import { AuthService } from './core/services/auth/auth.service';
import { RatingModule } from 'ngx-bootstrap/rating';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { PushNotificationService } from './core/services/push-notification/push-notification.service';
import { InitializerService, initProviderFactory } from './core/services/initializer/initializer.service';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, CONFIG } from '@angular/fire/analytics';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from '../environments/environment';
import { MatModule } from './core/shared/mat/mat.module';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HammerModule } from "../../node_modules/@angular/platform-browser";
import { ServiceWorkerModule } from '@angular/service-worker';
// import { NgxGaugeModule } from 'ngx-gauge';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    MatModule,
    HttpClientModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    HttpClientModule,
    RatingModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService],
      }
    }),
    ReactiveFormsModule,
    AngularFireMessagingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AppCommonModule,
    // AngularFontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately',
    }),
    MatDialogModule,
    MatModule,
    RouterModule,
    FontAwesomeModule,
    HammerModule,
    // NgxGaugeModule
  ],
  exports: [
    MatRadioModule,
    MatDialogModule,
    AppCommonModule
  ],
  providers: [
    DatePipe,
    AuthService,
    PushNotificationService,
    AsyncPipe,
    InitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitializerService],
      multi: true
    },
    AngularFirestore,
    ClipboardService,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: CONFIG,
      useValue: {
        DEBUG_MODE: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
