<div class="user-app-module">
    <div class="account-container">
        <div class="account-inner-container">
            <div class="container div_center">
                <div class="row">
                    <div class="col-12" style="margin-top: 20px;">
                        <mat-select class="form-control mat-select-left"
                            (selectionChange)="languageChange($event.value)" [(ngModel)]="language">
                            <mat-option *ngFor="let language of languages" [value]="language.name">
                                {{language.languageShown}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="logoDiv">
                            <img src="/assets/images/biblist-logo.png" class="logo">
                        </div>
                    </div>
                </div>

                <div class="row pinDiv">

                    <div class="col-12 title" *ngIf="!showHome">Login/Signup</div>

                    <div class="col-12" *ngIf="!showHome">
                        <button color="primary" class="login_btn" routerLink="/account/signin">{{applanguage?.Text_280}}</button>
                        <!-- <div><a routerLink="/account/signin"></a>
                        </div> -->
                    </div>

                    <div class="col-12" *ngIf="!showHome">
                        <button color="primary" class="register_btn" routerLink="/account/register">{{applanguage?.Text_279}}</button>
                        <!-- <div><a routerLink="/account/register">{{applanguage?.Text_279}}</a>
                        </div> -->
                    </div>

                    <div class="col-12" style="margin-top: 55px;" *ngIf="showHome">
                        <button color="primary" class="login_btn" (click)="gotoHome()">{{applanguage?.Text_44}}</button>

                        <!-- <div class="submitBtn center-align">
                            <button mat-raised-button color="primary"
                                (click)="gotoHome()">{{applanguage?.Text_44}}</button>
                        </div> -->
                    </div>

                    <div class="col-12 or_Div">{{applanguage?.Text_529}}</div>

                    <div class="col-12">
                        <app-pin [numOfDigits]="3" (sumitPin)="submitPin($event)"></app-pin>
                    </div>

                    <div class="col-12">
                        <!-- <div class="col-12"> -->
                            <div style="text-align: center;"><a href="https://biblist.net/">{{applanguage?.Text_278}}</a></div>
                        <!-- </div> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- <div class="user-app-containerss">
    <div class="container">

       
    </div>

</div> -->