export const $iconPath: string = '/assets/icons'
export const $imagePath: string = '/assets/images'
export const $icons: string[] = [
    'bible',
    'home',
    'questionAnswer',
    'rate',
    'report',
    'settings',
    'calendar',
    'global',
    'notifications',
    'notification-bell',
    'answer-question',
    'question',
    'games',
    'settings-alt',
    'alarm-clock',
    'help',
    'brain',
    'wheat-sack',
    'home-icon',
    'speech-bubble',
    'author',
    'share',
    'biblist-logout',
    'view-result',
    'google',
    'twitter',
    'facebook',
    'user',
    'logout',
    'group',
    'antibody',
    'info-question',
    'notification',
    'file-download',
    'company-info-icon',
    'preview',
    'filter-remove',
    'filter',
    'timer',
    'select-list',
    'invite_VF',
    'register_user',
    'steps',
    'restart_VF',
    'timer_VF',
    'bible_ref',
    'complete',
    'quarantine_VF',
    'hint_VF',
    'restart_level',
    'no_internet',
    'people-search',
    'bible-icon',
    'bible-svg',
    '502117-200-Kittl'
]