// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
  
    firebase: {
      apiKey: "AIzaSyCo-qfhnOL4oQkQbU9OksDvznb1Ljc2_RA",
      authDomain: "biblist-phase-2.firebaseapp.com",
      databaseURL: "https://biblist-phase-2.firebaseio.com",
      projectId: "biblist-phase-2",
      storageBucket: "biblist-phase-2.appspot.com",
      messagingSenderId: "190150982097",
      appId: "1:190150982097:web:991607bb3deccf3e4548f0"
    }
  
    // firebase: {
    //   apiKey: "AIzaSyDEMB6LbSI38uTHzLJpzGqCmbCKKLC6wgw",
    //   authDomain: "push-notification-b93fa.firebaseapp.com",
    //   databaseURL: "https://push-notification-b93fa.firebaseio.com",
    //   projectId: "push-notification-b93fa",
    //   storageBucket: "push-notification-b93fa.appspot.com",
    //   messagingSenderId: "143970246987",
    //   appId: "1:143970246987:web:0347906f81023c23c948ce",
    //   measurementId: "G-SRK727E8ZD"
    // }
  };
  
  // export const $IP = 'biblist.ruahtech.com'; //192.168.0.104
  // // export const $PORT = '4000';
  export const $IP = 'api-services.biblist.net'; //192.168.0.104
  export const $PORT = '4000';
  export const $DOMAIN = `${$IP}:${$PORT}`; //'49a7b81d2085.ngrok.io' //`${$IP}:${$PORT}`;
  // export const $DOMAIN = '1817acd7e95a.ngrok.io'; //'49a7b81d2085.ngrok.io' //`${$IP}:${$PORT}`;
  export const $URI = `https://${$DOMAIN}`;
  export const $REST_URI = `${$URI}/api`;
  export const $REST_URI_COMMON = `${$URI}/api/common`;
  export const $SOCKET_URI = `${$URI}`;
  // export const $PATH = {
  //   customerAvatar: `${$REST_URI}/customer/avatar`,
  //   employeeAvatar: `${$REST_URI}/employee/avatar`,
  //   asset: `${$REST_URI}/asset/file`
  // }
  export const $SOCKET_OPTIONS = {
    path: '/socket',
    query: {
      auth_token: localStorage.getItem('auth-token'),
      browserId: null
    },
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  