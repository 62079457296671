export const $APP_VALUES = {
  errorMessages: {
    default: 'Something went wrong, please try again'
  },
  statusFilterDates: [
    { code: 'WEEK', label: 'A week ago' },
    { code: 'MONTH', label: 'A month ago' },
    { code: 'SIX_MONTHS', label: '6 months ago' },
    { code: 'YEAR', label: 'An year ago' }
  ],
  languages: [{ code: 'ENGLISH', label: 'English' }, { code: 'GERMAN', label: 'German' }],
  optionsProperty: [
    { shortName: '1', name: 'option1', label: 'Option A', shortLabel: 'A' },
    { shortName: '2', name: 'option2', label: 'Option B', shortLabel: 'B' },
    { shortName: '3', name: 'option3', label: 'Option C', shortLabel: 'C' },
    { shortName: '4', name: 'option4', label: 'Option D', shortLabel: 'D' },
    { shortName: '5', name: 'option5', label: 'Option E', shortLabel: 'E' },
    { shortName: '6', name: 'option6', label: 'Option F', shortLabel: 'F' },
  ],
  questionStatuses: [
    { name: 'A', label: 'Status A', shortLabel: 'A', class: 'status-a' },
    { name: 'B', label: 'Status B', shortLabel: 'B', class: 'status-b' },
    { name: 'C', label: 'Status C', shortLabel: 'C', class: 'status-c' },
    { name: 'D', label: 'Status D', shortLabel: 'D', class: 'status-d' },
    { name: 'E', label: 'Status E', shortLabel: 'E', class: 'status-e' },
    { name: 'F', label: 'Status F', shortLabel: 'F', class: 'status-f' },
    { name: 'G', label: 'Status G', shortLabel: 'G', class: 'status-g' },
  ],
  approvalStatuses: [
    // { name: 'A', label: 'Status A', shortLabel: 'A', class: 'status-a' },
    { name: 'B', label: 'Status B', shortLabel: 'B', class: 'status-b' },
    { name: 'D', label: 'Status D', shortLabel: 'D', class: 'status-d' },
  ],

  questionStepperForms: {
    questionText: ['text', 'language', 'specificationTags', 'initialDifficultyLevel'],
    questionOption: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6', 'correctAnswerCount'],
    analytics: ['readingTime', 'averageTime', 'thinkingTime', 'cheatingTime', 'isSubmittableWithoutAnswer', 'isCatchy', 'isEasyToFind', 'applicability'],
    relatives: [],
    practice: ['surveyQuestion']
  },

  levels: [
    { code: 0, label: 'Random' },
    { code: 1, label: 'Level 1' },
    { code: 2, label: 'Level 2' },
    { code: 3, label: 'Level 3' },
    { code: 4, label: 'Level 4' },
    { code: 5, label: 'Level 5' },
    { code: 6, label: 'Level 6' },
    { code: 7, label: 'Level 7' },
  ],
  avatars: [
    { code: 'MAN', path: 'assets/images/avatars/man.png' },
    { code: 'MAN_ALT', path: 'assets/images/avatars/man-alt.png' },
    { code: 'WOMEN', path: 'assets/images/avatars/women.png' },
    { code: 'MAN_MASKED', path: 'assets/images/avatars/male.png' },
    { code: 'WOMEN_MASKED', path: 'assets/images/avatars/female.png' },
    { code: 'PP1', path: 'assets/images/avatars/pp1.svg' },
    { code: 'PP2', path: 'assets/images/avatars/pp2.svg' },
    { code: 'PP3', path: 'assets/images/avatars/pp3.svg' },
    { code: 'PP4', path: 'assets/images/avatars/pp4.svg' },
    { code: 'PP5', path: 'assets/images/avatars/pp5.svg' },
    { code: 'PP6', path: 'assets/images/avatars/pp6.svg' },
    { code: 'PP7', path: 'assets/images/avatars/pp7.svg' },
    { code: 'PP8', path: 'assets/images/avatars/pp8.svg' },
    { code: 'PP9', path: 'assets/images/avatars/pp9.svg' },
    { code: 'PP10', path: 'assets/images/avatars/pp10.svg' },
  ],

  alerts: {
    playersGreaterThanCells: 'To continue playing in this mode number of questions should be multiple of number of players. Proceeding will change the play mode to All At Once.',
    playersNotMultipleOfCells: 'To continue playing in this mode number of questions should be multiple of number of players. Proceeding will change the play mode to All At Once.'
  },

  questionsCount: [[
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false }
  ], [
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false }
  ], [
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false }
  ], [
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false }
  ], [
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false }
  ], [
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false }
  ], [
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false },
    { questionCount: 0, questions: [], selectedQuestion: null, status: null, isLoading: false }
  ]],

  countryFlag: [
    { name: "Afghanistan", code: "AF", flag: 'assets/icons/country-icons/af.png' },
    { name: "land Islands", code: "AX", flag: 'assets/icons/country-icons/ax.png' },
    { name: "Albania", code: "AL", flag: 'assets/icons/country-icons/al.png' },
    { name: "Algeria", code: "DZ", flag: 'assets/icons/country-icons/dz.png' },
    { name: "American Samoa", code: "AS", flag: 'assets/icons/country-icons/as.png' },
    { name: "AndorrA", code: "AD", flag: 'assets/icons/country-icons/ad.png' },
    { name: "Angola", code: "AO", flag: 'assets/icons/country-icons/ao.png' },
    { name: "Anguilla", code: "AI", flag: 'assets/icons/country-icons/ai.png' },
    { name: "Antarctica", code: "AQ", flag: 'assets/icons/country-icons/aq.png' },
    { name: "Antigua and Barbuda", code: "AG", flag: 'assets/icons/country-icons/ag.png' },
    { name: "Argentina", code: "AR", flag: 'assets/icons/country-icons/ar.png' },
    { name: "Armenia", code: "AM", flag: 'assets/icons/country-icons/am.png' },
    { name: "Aruba", code: "AW", flag: 'assets/icons/country-icons/aw.png' },
    { name: "Australia", code: "AU", flag: 'assets/icons/country-icons/au.png' },
    { name: "Austria", code: "AT", flag: 'assets/icons/country-icons/at.png' },
    { name: "Azerbaijan", code: "AZ", flag: 'assets/icons/country-icons/az.png' },
    { name: "Bahamas", code: "BS", flag: 'assets/icons/country-icons/bs.png' },
    { name: "Bahrain", code: "BH", flag: 'assets/icons/country-icons/bh.png' },
    { name: "Bangladesh", code: "BD", flag: 'assets/icons/country-icons/bd.png' },
    { name: "Barbados", code: "BB", flag: 'assets/icons/country-icons/bb.png' },
    { name: "Belarus", code: "BY", flag: 'assets/icons/country-icons/by.png' },
    { name: "Belgium", code: "BE", flag: 'assets/icons/country-icons/be.png' },
    { name: "Belize", code: "BZ", flag: 'assets/icons/country-icons/bz.png' },
    { name: "Benin", code: "BJ", flag: 'assets/icons/country-icons/bj.png' },
    { name: "Bermuda", code: "BM", flag: 'assets/icons/country-icons/bm.png' },
    { name: "Bhutan", code: "BT", flag: 'assets/icons/country-icons/bt.png' },
    { name: "Bolivia", code: "BO", flag: 'assets/icons/country-icons/bo.png' },
    { name: "Bosnia and Herzegovina", code: "BA", flag: 'assets/icons/country-icons/ba.png' },
    { name: "Botswana", code: "BW", flag: 'assets/icons/country-icons/bw.png' },
    { name: "Bouvet Island", code: "BV", flag: 'assets/icons/country-icons/bv.png' },
    { name: "Brazil", code: "BR", flag: 'assets/icons/country-icons/br.png' },
    { name: "British Indian Ocean Territory", code: "IO", flag: 'assets/icons/country-icons/io.png' },
    { name: "Brunei Darussalam", code: "BN", flag: 'assets/icons/country-icons/bn.png' },
    { name: "Bulgaria", code: "BG", flag: 'assets/icons/country-icons/bg.png' },
    { name: "Burkina Faso", code: "BF", flag: 'assets/icons/country-icons/bf.png' },
    { name: "Burundi", code: "BI", flag: 'assets/icons/country-icons/bi.png' },
    { name: "Cambodia", code: "KH", flag: 'assets/icons/country-icons/kh.png' },
    { name: "Cameroon", code: "CM", flag: 'assets/icons/country-icons/cm.png' },
    { name: "Canada", code: "CA", flag: 'assets/icons/country-icons/ca.png' },
    { name: "Cape Verde", code: "CV", flag: 'assets/icons/country-icons/cv.png' },
    { name: "Cayman Islands", code: "KY", flag: 'assets/icons/country-icons/ky.png' },
    { name: "Central African Republic", code: "CF", flag: 'assets/icons/country-icons/cf.png' },
    { name: "Chad", code: "TD", flag: 'assets/icons/country-icons/td.png' },
    { name: "Chile", code: "CL", flag: 'assets/icons/country-icons/cl.png' },
    { name: "China", code: "CN", flag: 'assets/icons/country-icons/cn.png' },
    { name: "Christmas Island", code: "CX", flag: 'assets/icons/country-icons/cx.png' },
    { name: "Cocos (Keeling) Islands", code: "CC", flag: 'assets/icons/country-icons/cc.png' },
    { name: "Colombia", code: "CO", flag: 'assets/icons/country-icons/co.png' },
    { name: "Comoros", code: "KM", flag: 'assets/icons/country-icons/km.png' },
    { name: "Congo", code: "CG", flag: 'assets/icons/country-icons/cg.png' },
    { name: "Congo, The Democratic Republic of the", code: "CD", flag: 'assets/icons/country-icons/cd.png' },
    { name: "Cook Islands", code: "CK", flag: 'assets/icons/country-icons/ck.png' },
    { name: "Costa Rica", code: "CR", flag: 'assets/icons/country-icons/cr.png' },
    { name: "Cote D\"Ivoire", code: "CI", flag: 'assets/icons/country-icons/ci.png' },
    { name: "Croatia", code: "HR", flag: 'assets/icons/country-icons/hr.png' },
    { name: "Cuba", code: "CU", flag: 'assets/icons/country-icons/cu.png' },
    { name: "Cyprus", code: "CY", flag: 'assets/icons/country-icons/cy.png' },
    { name: "Czech Republic", code: "CZ", flag: 'assets/icons/country-icons/cz.png' },
    { name: "Denmark", code: "DK", flag: 'assets/icons/country-icons/dk.png' },
    { name: "Djibouti", code: "DJ", flag: 'assets/icons/country-icons/dj.png' },
    { name: "Dominica", code: "DM", flag: 'assets/icons/country-icons/dm.png' },
    { name: "Dominican Republic", code: "DO", flag: 'assets/icons/country-icons/do.png' },
    { name: "Ecuador", code: "EC", flag: 'assets/icons/country-icons/ec.png' },
    { name: "Egypt", code: "EG", flag: 'assets/icons/country-icons/eg.png' },
    { name: "El Salvador", code: "SV", flag: 'assets/icons/country-icons/sv.png' },
    { name: "Equatorial Guinea", code: "GQ", flag: 'assets/icons/country-icons/gq.png' },
    { name: "Eritrea", code: "ER", flag: 'assets/icons/country-icons/er.png' },
    { name: "Estonia", code: "EE", flag: 'assets/icons/country-icons/ee.png' },
    { name: "Ethiopia", code: "ET", flag: 'assets/icons/country-icons/et.png' },
    { name: "Falkland Islands (Malvinas)", code: "FK", flag: 'assets/icons/country-icons/fk.png' },
    { name: "Faroe Islands", code: "FO", flag: 'assets/icons/country-icons/fo.png' },
    { name: "Fiji", code: "FJ", flag: 'assets/icons/country-icons/fj.png' },
    { name: "Finland", code: "FI", flag: 'assets/icons/country-icons/fi.png' },
    { name: "France", code: "FR", flag: 'assets/icons/country-icons/fr.png' },
    { name: "French Guiana", code: "GF", flag: 'assets/icons/country-icons/gf.png' },
    { name: "French Polynesia", code: "PF", flag: 'assets/icons/country-icons/pf.png' },
    { name: "French Southern Territories", code: "TF", flag: 'assets/icons/country-icons/tf.png' },
    { name: "Gabon", code: "GA", flag: 'assets/icons/country-icons/ga.png' },
    { name: "Gambia", code: "GM", flag: 'assets/icons/country-icons/gm.png' },
    { name: "Georgia", code: "GE", flag: 'assets/icons/country-icons/ge.png' },
    { name: "Germany", code: "DE", flag: 'assets/icons/country-icons/de.png' },
    { name: "Ghana", code: "GH", flag: 'assets/icons/country-icons/gh.png' },
    { name: "Gibraltar", code: "GI", flag: 'assets/icons/country-icons/gi.png' },
    { name: "Greece", code: "GR", flag: 'assets/icons/country-icons/gr.png' },
    { name: "Greenland", code: "GL", flag: 'assets/icons/country-icons/gl.png' },
    { name: "Grenada", code: "GD", flag: 'assets/icons/country-icons/gd.png' },
    { name: "Guadeloupe", code: "GP", flag: 'assets/icons/country-icons/gp.png' },
    { name: "Guam", code: "GU", flag: 'assets/icons/country-icons/gu.png' },
    { name: "Guatemala", code: "GT", flag: 'assets/icons/country-icons/gt.png' },
    { name: "Guernsey", code: "GG", flag: 'assets/icons/country-icons/gg.png' },
    { name: "Guinea", code: "GN", flag: 'assets/icons/country-icons/gn.png' },
    { name: "Guinea-Bissau", code: "GW", flag: 'assets/icons/country-icons/gw.png' },
    { name: "Guyana", code: "GY", flag: 'assets/icons/country-icons/gy.png' },
    { name: "Haiti", code: "HT", flag: 'assets/icons/country-icons/ht.png' },
    { name: "Heard Island and Mcdonald Islands", code: "HM", flag: 'assets/icons/country-icons/hm.png' },
    { name: "Holy See (Vatican City State)", code: "VA", flag: 'assets/icons/country-icons/va.png' },
    { name: "Honduras", code: "HN", flag: 'assets/icons/country-icons/hn.png' },
    { name: "Hong Kong", code: "HK", flag: 'assets/icons/country-icons/hk.png' },
    { name: "Hungary", code: "HU", flag: 'assets/icons/country-icons/hu.png' },
    { name: "Iceland", code: "IS", flag: 'assets/icons/country-icons/is.png' },
    { name: "India", code: "IN", flag: 'assets/icons/country-icons/in.png' },
    { name: "Indonesia", code: "ID", flag: 'assets/icons/country-icons/id.png' },
    { name: "Iran, Islamic Republic Of", code: "IR", flag: 'assets/icons/country-icons/ir.png' },
    { name: "Iraq", code: "IQ", flag: 'assets/icons/country-icons/iq.png' },
    { name: "Ireland", code: "IE", flag: 'assets/icons/country-icons/ie.png' },
    { name: "Isle of Man", code: "IM", flag: 'assets/icons/country-icons/im.png' },
    { name: "Israel", code: "IL", flag: 'assets/icons/country-icons/il.png' },
    { name: "Italy", code: "IT", flag: 'assets/icons/country-icons/it.png' },
    { name: "Jamaica", code: "JM", flag: 'assets/icons/country-icons/jm.png' },
    { name: "Japan", code: "JP", flag: 'assets/icons/country-icons/jp.png' },
    { name: "Jersey", code: "JE", flag: 'assets/icons/country-icons/je.png' },
    { name: "Jordan", code: "JO", flag: 'assets/icons/country-icons/jo.png' },
    { name: "Kazakhstan", code: "KZ", flag: 'assets/icons/country-icons/kz.png' },
    { name: "Kenya", code: "KE", flag: 'assets/icons/country-icons/ke.png' },
    { name: "Kiribati", code: "KI", flag: 'assets/icons/country-icons/ki.png' },
    { name: "Korea, Democratic People\"S Republic of", code: "KP", flag: 'assets/icons/country-icons/kp.png' },
    { name: "Korea, Republic of", code: "KR", flag: 'assets/icons/country-icons/kr.png' },
    { name: "Kuwait", code: "KW", flag: 'assets/icons/country-icons/kw.png' },
    { name: "Kyrgyzstan", code: "KG", flag: 'assets/icons/country-icons/kg.png' },
    { name: "Lao People\"S Democratic Republic", code: "LA", flag: 'assets/icons/country-icons/la.png' },
    { name: "Latvia", code: "LV", flag: 'assets/icons/country-icons/lv.png' },
    { name: "Lebanon", code: "LB", flag: 'assets/icons/country-icons/lb.png' },
    { name: "Lesotho", code: "LS", flag: 'assets/icons/country-icons/ls.png' },
    { name: "Liberia", code: "LR", flag: 'assets/icons/country-icons/lr.png' },
    { name: "Libyan Arab Jamahiriya", code: "LY", flag: 'assets/icons/country-icons/ly.png' },
    { name: "Liechtenstein", code: "LI", flag: 'assets/icons/country-icons/li.png' },
    { name: "Lithuania", code: "LT", flag: 'assets/icons/country-icons/lt.png' },
    { name: "Luxembourg", code: "LU", flag: 'assets/icons/country-icons/lu.png' },
    { name: "Macao", code: "MO", flag: 'assets/icons/country-icons/mo.png' },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK", flag: 'assets/icons/country-icons/mk.png' },
    { name: "Madagascar", code: "MG", flag: 'assets/icons/country-icons/mg.png' },
    { name: "Malawi", code: "MW", flag: 'assets/icons/country-icons/mw.png' },
    { name: "Malaysia", code: "MY", flag: 'assets/icons/country-icons/my.png' },
    { name: "Maldives", code: "MV", flag: 'assets/icons/country-icons/mv.png' },
    { name: "Mali", code: "ML", flag: 'assets/icons/country-icons/ml.png' },
    { name: "Malta", code: "MT", flag: 'assets/icons/country-icons/mt.png' },
    { name: "Marshall Islands", code: "MH", flag: 'assets/icons/country-icons/mh.png' },
    { name: "Martinique", code: "MQ", flag: 'assets/icons/country-icons/mq.png' },
    { name: "Mauritania", code: "MR", flag: 'assets/icons/country-icons/mr.png' },
    { name: "Mauritius", code: "MU", flag: 'assets/icons/country-icons/mu.png' },
    { name: "Mayotte", code: "YT", flag: 'assets/icons/country-icons/yt.png' },
    { name: "Mexico", code: "MX", flag: 'assets/icons/country-icons/mx.png' },
    { name: "Micronesia, Federated States of", code: "FM", flag: 'assets/icons/country-icons/fm.png' },
    { name: "Moldova, Republic of", code: "MD", flag: 'assets/icons/country-icons/md.png' },
    { name: "Monaco", code: "MC", flag: 'assets/icons/country-icons/mc.png' },
    { name: "Mongolia", code: "MN", flag: 'assets/icons/country-icons/mn.png' },
    { name: "Montenegro", code: "ME", flag: 'assets/icons/country-icons/me.png' },
    { name: "Montserrat", code: "MS", flag: 'assets/icons/country-icons/ms.png' },
    { name: "Morocco", code: "MA", flag: 'assets/icons/country-icons/ma.png' },
    { name: "Mozambique", code: "MZ", flag: 'assets/icons/country-icons/mz.png' },
    { name: "Myanmar", code: "MM", flag: 'assets/icons/country-icons/mm.png' },
    { name: "Namibia", code: "NA", flag: 'assets/icons/country-icons/na.png' },
    { name: "Nauru", code: "NR", flag: 'assets/icons/country-icons/nr.png' },
    { name: "Nepal", code: "NP", flag: 'assets/icons/country-icons/np.png' },
    { name: "Netherlands", code: "NL", flag: 'assets/icons/country-icons/nl.png' },
    { name: "Netherlands Antilles", code: "AN", flag: 'assets/icons/country-icons/an.png' },
    { name: "New Caledonia", code: "NC", flag: 'assets/icons/country-icons/nc.png' },
    { name: "New Zealand", code: "NZ", flag: 'assets/icons/country-icons/nz.png' },
    { name: "Nicaragua", code: "NI", flag: 'assets/icons/country-icons/ni.png' },
    { name: "Niger", code: "NE", flag: 'assets/icons/country-icons/ne.png' },
    { name: "Nigeria", code: "NG", flag: 'assets/icons/country-icons/ng.png' },
    { name: "Niue", code: "NU", flag: 'assets/icons/country-icons/nu.png' },
    { name: "Norfolk Island", code: "NF", flag: 'assets/icons/country-icons/nf.png' },
    { name: "Northern Mariana Islands", code: "MP", flag: 'assets/icons/country-icons/mp.png' },
    { name: "Norway", code: "NO", flag: 'assets/icons/country-icons/no.png' },
    { name: "Oman", code: "OM", flag: 'assets/icons/country-icons/om.png' },
    { name: "Pakistan", code: "PK", flag: 'assets/icons/country-icons/pk.png' },
    { name: "Palau", code: "PW", flag: 'assets/icons/country-icons/pw.png' },
    { name: "Palestinian Territory, Occupied", code: "PS", flag: 'assets/icons/country-icons/ps.png' },
    { name: "Panama", code: "PA", flag: 'assets/icons/country-icons/pa.png' },
    { name: "Papua New Guinea", code: "PG", flag: 'assets/icons/country-icons/pg.png' },
    { name: "Paraguay", code: "PY", flag: 'assets/icons/country-icons/py.png' },
    { name: "Peru", code: "PE", flag: 'assets/icons/country-icons/pe.png' },
    { name: "Philippines", code: "PH", flag: 'assets/icons/country-icons/ph.png' },
    { name: "Pitcairn", code: "PN", flag: 'assets/icons/country-icons/pn.png' },
    { name: "Poland", code: "PL", flag: 'assets/icons/country-icons/pl.png' },
    { name: "Portugal", code: "PT", flag: 'assets/icons/country-icons/pt.png' },
    { name: "Puerto Rico", code: "PR", flag: 'assets/icons/country-icons/pr.png' },
    { name: "Qatar", code: "QA", flag: 'assets/icons/country-icons/qa.png' },
    { name: "Reunion", code: "RE", flag: 'assets/icons/country-icons/re.png' },
    { name: "Romania", code: "RO", flag: 'assets/icons/country-icons/ro.png' },
    { name: "Russian Federation", code: "RU", flag: 'assets/icons/country-icons/ru.png' },
    { name: "RWANDA", code: "RW", flag: 'assets/icons/country-icons/rw.png' },
    { name: "Saint Helena", code: "SH", flag: 'assets/icons/country-icons/sh.png' },
    { name: "Saint Kitts and Nevis", code: "KN", flag: 'assets/icons/country-icons/kn.png' },
    { name: "Saint Lucia", code: "LC", flag: 'assets/icons/country-icons/lc.png' },
    { name: "Saint Pierre and Miquelon", code: "PM", flag: 'assets/icons/country-icons/pm.png' },
    { name: "Saint Vincent and the Grenadines", code: "VC", flag: 'assets/icons/country-icons/vc.png' },
    { name: "Samoa", code: "WS", flag: 'assets/icons/country-icons/ws.png' },
    { name: "San Marino", code: "SM", flag: 'assets/icons/country-icons/sm.png' },
    { name: "Sao Tome and Principe", code: "ST", flag: 'assets/icons/country-icons/st.png' },
    { name: "Saudi Arabia", code: "SA", flag: 'assets/icons/country-icons/sa.png' },
    { name: "Senegal", code: "SN", flag: 'assets/icons/country-icons/sn.png' },
    { name: "Serbia", code: "RS", flag: 'assets/icons/country-icons/rs.png' },
    { name: "Seychelles", code: "SC", flag: 'assets/icons/country-icons/sc.png' },
    { name: "Sierra Leone", code: "SL", flag: 'assets/icons/country-icons/sl.png' },
    { name: "Singapore", code: "SG", flag: 'assets/icons/country-icons/sg.png' },
    { name: "Slovakia", code: "SK", flag: 'assets/icons/country-icons/sk.png' },
    { name: "Slovenia", code: "SI", flag: 'assets/icons/country-icons/si.png' },
    { name: "Solomon Islands", code: "SB", flag: 'assets/icons/country-icons/sb.png' },
    { name: "Somalia", code: "SO", flag: 'assets/icons/country-icons/so.png' },
    { name: "South Africa", code: "ZA", flag: 'assets/icons/country-icons/za.png' },
    { name: "South Georgia and the South Sandwich Islands", code: "GS", flag: 'assets/icons/country-icons/gs.png' },
    { name: "Spain", code: "ES", flag: 'assets/icons/country-icons/es.png' },
    { name: "Sri Lanka", code: "LK", flag: 'assets/icons/country-icons/lk.png' },
    { name: "Sudan", code: "SD", flag: 'assets/icons/country-icons/sd.png' },
    { name: "Suriname", code: "SR", flag: 'assets/icons/country-icons/sr.png' },
    { name: "Svalbard and Jan Mayen", code: "SJ", flag: 'assets/icons/country-icons/sj.png' },
    { name: "Swaziland", code: "SZ", flag: 'assets/icons/country-icons/sz.png' },
    { name: "Sweden", code: "SE", flag: 'assets/icons/country-icons/se.png' },
    { name: "Switzerland", code: "CH", flag: 'assets/icons/country-icons/ch.png' },
    { name: "Syrian Arab Republic", code: "SY", flag: 'assets/icons/country-icons/sy.png' },
    { name: "Taiwan, Province of China", code: "TW", flag: 'assets/icons/country-icons/tw.png' },
    { name: "Tajikistan", code: "TJ", flag: 'assets/icons/country-icons/tj.png' },
    { name: "Tanzania, United Republic of", code: "TZ", flag: 'assets/icons/country-icons/tz.png' },
    { name: "Thailand", code: "TH", flag: 'assets/icons/country-icons/th.png' },
    { name: "Timor-Leste", code: "TL", flag: 'assets/icons/country-icons/tl.png' },
    { name: "Togo", code: "TG", flag: 'assets/icons/country-icons/tg.png' },
    { name: "Tokelau", code: "TK", flag: 'assets/icons/country-icons/tk.png' },
    { name: "Tonga", code: "TO", flag: 'assets/icons/country-icons/to.png' },
    { name: "Trinidad and Tobago", code: "TT", flag: 'assets/icons/country-icons/tt.png' },
    { name: "Tunisia", code: "TN", flag: 'assets/icons/country-icons/tn.png' },
    { name: "Turkey", code: "TR", flag: 'assets/icons/country-icons/tr.png' },
    { name: "Turkmenistan", code: "TM", flag: 'assets/icons/country-icons/tm.png' },
    { name: "Turks and Caicos Islands", code: "TC", flag: 'assets/icons/country-icons/tc.png' },
    { name: "Tuvalu", code: "TV", flag: 'assets/icons/country-icons/tv.png' },
    { name: "Uganda", code: "UG", flag: 'assets/icons/country-icons/ug.png' },
    { name: "Ukraine", code: "UA", flag: 'assets/icons/country-icons/ua.png' },
    { name: "United Arab Emirates", code: "AE", flag: 'assets/icons/country-icons/ae.png' },
    { name: "United Kingdom", code: "GB", flag: 'assets/icons/country-icons/gb.png' },
    { name: "United States", code: "US", flag: 'assets/icons/country-icons/us.png' },
    { name: "United States Minor Outlying Islands", code: "UM", flag: 'assets/icons/country-icons/um.png' },
    { name: "Uruguay", code: "UY", flag: 'assets/icons/country-icons/uy.png' },
    { name: "Uzbekistan", code: "UZ", flag: 'assets/icons/country-icons/uz.png' },
    { name: "Vanuatu", code: "VU", flag: 'assets/icons/country-icons/vu.png' },
    { name: "Venezuela", code: "VE", flag: 'assets/icons/country-icons/ve.png' },
    { name: "Viet Nam", code: "VN", flag: 'assets/icons/country-icons/vn.png' },
    { name: "Virgin Islands, British", code: "VG", flag: 'assets/icons/country-icons/vg.png' },
    { name: "Virgin Islands, U.S.", code: "VI", flag: 'assets/icons/country-icons/vi.png' },
    { name: "Wallis and Futuna", code: "WF", flag: 'assets/icons/country-icons/wf.png' },
    { name: "Western Sahara", code: "EH", flag: 'assets/icons/country-icons/eh.png' },
    { name: "Yemen", code: "YE", flag: 'assets/icons/country-icons/ye.png' },
    { name: "Zambia", code: "ZM", flag: 'assets/icons/country-icons/zm.png' },
    { name: "Zimbabwe", code: "ZW", flag: 'assets/icons/country-icons/zw.png' },

    { name: "South Sudan", code: "SS", flag: 'assets/icons/country-icons/ss.png' },
    { name: "caribbean nl", code: "BQ", flag: 'assets/icons/country-icons/bq.png' },
    { name: "curaçao", code: "CW", flag: 'assets/icons/country-icons/cw.png' },
    { name: "St Barthelemy", code: "BL", flag: 'assets/icons/country-icons/bl.png' },
    { name: "St Maarten (Dutch)", code: "SX", flag: 'assets/icons/country-icons/sx.png' },
    { name: "St Martin (French)", code: "MF", flag: 'assets/icons/country-icons/mf.png' },

  ],

  // Functions or Callbacks
  filterPredicate
};


function filterPredicate(data: any, filter: string) {
  let temp: { val: string[], column: string }[] = JSON.parse(filter);
  let found = true;
  for (let i = 0; i < temp.length; i++) {
    // console.log(data[temp[i].column].toString(), temp[i].val);
    found = !!temp[i].val.find(ele => data[temp[i].column].toString().indexOf(ele) > -1);
    // console.log(found)
    if (!found) break;
  }
  return found;
}

export interface QuestionOptionProperty {
  shortName: string,
  name: string,
  label: string,
  shortLabel: string,
}

// export interface countryFlag {
//   flag: [
//     {
//       code: 'ad',
//       ad: 'assets/icons/country-icons/ad.png',
//     },
//     {
//       code: 'ae',
//       ae: 'assets/icons/country-icons/ae.png',
//     },
//     {
//       code: 'af',
//       af: 'assets/icons/country-icons/af.png',
//     },
//     {
//       code: 'ag',
//       ag: 'assets/icons/country-icons/ag.png',
//     },
//     {
//       code: 'ai',
//       ai: 'assets/icons/country-icons/ai.png',
//     },
//     {
//       code: 'al',
//       al: 'assets/icons/country-icons/al.png',
//     },
//     {
//       code: 'am',
//       am: 'assets/icons/country-icons/am.png',
//     },
//     {
//       code: 'an',
//       an: 'assets/icons/country-icons/an.png',
//     },
//     {
//       code: 'ao',
//       ao: 'assets/icons/country-icons/ao.png',
//     },
//     {
//       code: 'aq',
//       aq: 'assets/icons/country-icons/aq.png',
//     },
//     {
//       code: 'ar',
//       ar: 'assets/icons/country-icons/ar.png',
//     },
//     {
//       code: 'as',
//       as: 'assets/icons/country-icons/as.png',
//     },
//     {
//       code: 'at',
//       at: 'assets/icons/country-icons/at.png',
//     },
//     {
//       code: 'au',
//       au: 'assets/icons/country-icons/au.png',
//     },
//     {
//       code: 'aw',
//       aw: 'assets/icons/country-icons/aw.png',
//     },
//     {
//       code: 'ax',
//       ax: 'assets/icons/country-icons/ax.png',
//     },
//     {
//       code: 'az',
//       az: 'assets/icons/country-icons/az.png',
//     },
//     {
//       code: 'ba',
//       ba: 'assets/icons/country-icons/ba.png',
//     },
//     {
//       code: 'bb',
//       bb: 'assets/icons/country-icons/bb.png',
//     },
//     {
//       code: 'bd',
//       bd: 'assets/icons/country-icons/bd.png',
//     },
//     {
//       code: 'be',
//       be: 'assets/icons/country-icons/be.png',
//     },
//     {
//       code: 'bf',
//       bf: 'assets/icons/country-icons/bf.png',
//     },
//     {
//       code: 'bg',
//       bg: 'assets/icons/country-icons/bg.png',
//     },
//     {
//       code: 'bh',
//       bh: 'assets/icons/country-icons/bh.png',
//     },
//     {
//       code: 'bi',
//       bi: 'assets/icons/country-icons/bi.png',
//     },
//     {
//       code: 'bj',
//       bj: 'assets/icons/country-icons/bj.png',
//     },
//     {
//       code: 'bl',
//       bl: 'assets/icons/country-icons/bl.png',
//     },
//     {
//       code: 'bm',
//       bm: 'assets/icons/country-icons/bm.png',
//     },
//     {
//       code: 'bn',
//       bn: 'assets/icons/country-icons/bn.png',
//     },
//     {
//       code: 'bo',
//       bo: 'assets/icons/country-icons/bo.png',
//     },
//     {
//       code: 'bq',
//       bq: 'assets/icons/country-icons/bq.png',
//     },
//     {
//       code: 'br',
//       br: 'assets/icons/country-icons/br.png',
//     },
//     {
//       code: 'bs',
//       bs: 'assets/icons/country-icons/bs.png',
//     },
//     {
//       code: 'bt',
//       bt: 'assets/icons/country-icons/bt.png',
//     },
//     {
//       code: 'bv',
//       bv: 'assets/icons/country-icons/bv.png',
//     },
//   ]
// }