import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppLanguage } from 'src/app/core/model/app-language.model';
import { User } from 'src/app/core/model/user.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { GameService } from 'src/app/core/services/game/game.service';
import { SnackBarService } from 'src/app/core/services/snackbar/snack-bar.service';
import { UserService } from 'src/app/core/services/user/user.service';

export interface GameResponse {
  creationDate: string,
  game: string,
  gameId: string,
  lastModifiedDate: string,
  pin: string,
  socketId: string,
  _id: string,
}
@Component({
  selector: 'app-via-pin',
  templateUrl: './via-pin.component.html',
  styleUrls: ['./via-pin.component.scss']
})
export class ViaPinComponent implements OnInit {

  showHome: boolean = false;

  constructor(
    private dataService: DataService,
    private router: Router,
    private snackBar: SnackBarService,
    private userService: UserService,
    private gameService: GameService,
    private authService: AuthService
  ) { }

  languages;
  language;

  // applanguage: AppLanguage;
  applanguage: any;

  currentUser: User;

  submitPin(value) {
    console.log("submit pin", value);
    if (value) {
      this.dataService.getGameGroup(value).subscribe(res => {
        console.log("get game pin ", res);
        // let URL = `${location.origin}/games/sieve/game/`+res[0].gameId+"?pin="+res[0].pin+"&viaPin=true";
        // window.open(URL, '_self');
        this.authService.getToken().then(token => {
          if (this.gameService.socketConnected === false) {
            this.gameService.connect('game', token || '');
            this.gameService.connected.subscribe(() => {
              // resolve(true);
              console.log("socket connected");
            });
          }
        });
        setTimeout(() => {
          this.router.navigate(['games/sieve/game/', res[0].gameId], { queryParams: { pin: res[0].pin, viaPin: true } });
        }, 1000);
      }, err => {
        this.snackBar.info(err.error);
      });
    } else {
      this.snackBar.info(this.applanguage?.Text_299)
    }
  }

  languageChange(event) {
    console.log("language cahnge", event);
    this.language = event;
    localStorage.setItem('appLanguage', event);
    this.dataService.getAppLanguage(event).subscribe(res => {
      this.dataService._appLanguagesData.next(res);
    });
  }

  gotoHome() {
    this.router.navigate(['/home']);
  }

  ngOnInit() {
    this.dataService.getLanguage().subscribe(res => {
      console.log('languages', res);
      this.languages = res;
    });
    if (localStorage.getItem('appLanguage')) {
      this.language = localStorage.getItem('appLanguage');
    } else {
      this.language = "ENGLISH";
    }
    this.dataService.appLanguagesData.subscribe(res => {
      this.applanguage = res;
    });

    this.userService.currentUser.subscribe(res => {
      this.currentUser = res;
      if (this.currentUser?.firebaseUid) {
        this.showHome = true;
      }
    })

  }

}
