import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { $REST_URI } from 'src/environments/environment';
import firebase from 'firebase/app';
import { User } from '../../model/user.model';
import { PushNotificationService } from '../push-notification/push-notification.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();

  constructor(
    public fireAuth: AngularFireAuth,
    public router: Router,
    private http: HttpClient,
    private messagingService: PushNotificationService,
  ) { }

  login(email: string, password: string) {
    return this.fireAuth.signInWithEmailAndPassword(email, password);
  }

  signUp(data, token: string) {

    let header = new HttpHeaders();
    header = header.append('x-auth-token', token);

    return this.http.post(`${$REST_URI}/user/signup`, data, { headers: header });
  }

  signUpUser(data, token: string) {

    console.log("signup data", data);
    let params = new HttpParams();

    let data1;
    data1 = {
      name: data.name,
      country: data.country,
      language: data.language,
      invitedBy: data.invitedBy
    }

    if(data.contactNumber) {
      data1.contactNumber = data.contactNumber;
    }

    if(data.callingCode) {
      data1.callingCode = data.callingCode;
    }

    if(data.gameId) {
      data1.gameId = data.gameId;
    }

    if (this.messagingService.token) {
      let fcmToken;
      fcmToken = this.messagingService.token;
      console.log("browser Token:", fcmToken);
      if(fcmToken) {
        params = params.append('fcmToken', fcmToken);
      }
    }

    console.log("signup data", data1);

    let header = new HttpHeaders();
    header = header.append('x-auth-token', token);

    return this.http.post<User>(`${$REST_URI}/user/signup/client`, data1, { headers: header, params });
  }

  getToken() {
    return new Promise((resolve, reject) => {
      this.fireAuth.idToken.subscribe(token => {
        localStorage.setItem('auth-token', token);
        resolve(token);
      })
    });
  }

  logout() {
    return this.fireAuth.signOut();
  }

  forgotPassword(email: string) {
    return this.fireAuth.sendPasswordResetEmail(email);
  }

  changePassword(data: any) {
    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, data.password);

    return currentUser.reauthenticateWithCredential(credential).then((res) => {
      return currentUser.updatePassword(data.newPassword)
    })
  }


}