import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarLoaderComponent } from '../../shared/app-common/snack-bar-loader/snack-bar-loader.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  info(message, action = 'Close') {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: ['custom-snackbar', 'info']
    });
  }

  error(message, action = 'Close') {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: ['custom-snackbar', 'danger']
    });
  }

  loader(text?: string): MatSnackBarRef<SnackBarLoaderComponent> {
    return this.snackBar.openFromComponent(SnackBarLoaderComponent, {
      panelClass: 'loader',
      horizontalPosition: "left",
      verticalPosition: "bottom",
      data: text || 'Processing'
    });
  }

}
