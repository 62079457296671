import { Injectable, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { $SOCKET_OPTIONS, $SOCKET_URI, $REST_URI } from 'src/environments/environment';
import * as io from "socket.io-client";
import { Game, Player, Round, Mapping, CreateGame } from 'src/app/core/model/game.model';
import { SocketResponse } from 'src/app/core/model/game.model';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../user/user.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SnackBarService } from '../snackbar/snack-bar.service';
import { Router } from '@angular/router';
import { AppLanguage } from '../../model/app-language.model';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  isJoined: boolean = false;

  socket;

  form: FormGroup;

  game: BehaviorSubject<Game> = new BehaviorSubject(null);
  showNextBtn: BehaviorSubject<boolean> = new BehaviorSubject(false);

  newJoinee = new EventEmitter<Player>();

  gameStarted = new EventEmitter<SocketResponse<Game>>();

  connected = new EventEmitter<void>();

  selectQuestionRes = new EventEmitter<any>();

  chooserResponse = new EventEmitter<any>();

  answerQuestionRes = new EventEmitter<Game>();

  nextRound = new EventEmitter<{ gameId: string, round: Round }>();

  timeUp = new EventEmitter<any>();

  gameOver = new EventEmitter<Game>();

  skippedUserResponse = new EventEmitter<Game>();

  newGameResponse = new EventEmitter<SocketResponse<Game>>();

  joinGameResponse = new EventEmitter<SocketResponse<Game>>();

  getPublicGamesResponse = new EventEmitter<SocketResponse<Game[]>>();

  nextQuestionResponse: EventEmitter<SocketResponse<Game>> = new EventEmitter();

  myQuestionResponse: EventEmitter<SocketResponse<any>> = new EventEmitter();

  removeFromLobbyResponse: EventEmitter<any> = new EventEmitter(); //{ gameId: string, playerId: string }

  changeSettingsResponse = new EventEmitter<Game>();

  regenerateGamePinResponse = new EventEmitter<Game>();

  selectedOptionResponse = new EventEmitter<any>();

  changeAvatarResponse = new EventEmitter<any>();

  cancelGameResponse = new EventEmitter<any>();

  connectedResponse = new EventEmitter<any>();

  disconnectGameResponse = new EventEmitter<any>();

  projectorModeResponse = new EventEmitter<any>();

  gameChangeResponse = new EventEmitter<any>();

  socketConnected = false;

  // appLanguage: AppLanguage;
  appLanguage: any;

  manager;

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private snackBar: SnackBarService,
    private router: Router,
    private dataService: DataService,
  ) { }

  getAchievements(id: string) {
    return this.http.get(`${$REST_URI}/game/sieve/achievements/${id}`)
  }

  getGame(id: string, language?: string) {

    let query = new HttpParams();
    if (language) {
      query = query.append('language', language);
    }
    
    return this.http.get<Game>(`${$REST_URI}/game/sieve/${id}`, { params: query })
  }

  getPing() {
    return this.http.get<any>(`${$REST_URI}/game/sieve/ping`);
  }

  // retryConnectOnFailure(retryInMilliseconds) {
  //   setTimeout(() => {
  //     if (!this.socketConnected) {
  //       this.getPing().subscribe(res => {
  //         this.socketConnected = true;
  //         // window.location.href = unescape(window.location.pathname);
  //       });
  //       // retryConnectOnFailure(retryInMilliseconds);
  //     }
  //   }, retryInMilliseconds);
  // }

  connect(namespace: string, token) {
    const options = $SOCKET_OPTIONS;
    if (token) {
      options.query.auth_token = token;
    } else {
      options.query.browserId = this.userService.getAnonymousUser().anonymousId;
    }
    this.manager = new io.Manager($SOCKET_URI, options);
    this.socket = this.manager.socket(`/${namespace}`);

    this.socket.connect();
    this.socket.on('connect', () => {

      console.log(this.socket);
      console.log(`Socket connected to ${$SOCKET_URI} at ${namespace}.`);
      // this.socket.emit('reconnect', namespace)
      this.socketConnected = true;
      this.connected.emit();
    });

    this.socket.on('reconnecting', () => {
      console.log(`Reconnecting`);
      // this.socket.emit('reconnect', namespace)
    });

    this.socket.on('connect_error', (error) => {
      console.error(`Socket connection error: `, error);
    });

    this.socket.on('disconnect', () => {
      // this.socketConnected = false;
      console.log('disconnected');
      this.socketConnected = false;
      // setTimeout(() => {
      //   if (!this.socketConnected) {
      //     this.getPing().subscribe(res => {
      //       console.log("get ping res", res);
      //       this.socketConnected = true;
      //       // window.location.href = unescape(window.location.pathname);
      //     });
      //     // retryConnectOnFailure(retryInMilliseconds);
      //   }
      // }, 10000);
    });

    this.socket.on('newJoinee', (data: SocketResponse<Player>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      this.newJoinee.emit(data.data);
    });

    this.socket.on('startGameResponse', (data: SocketResponse<Game>) => {
      this.gameStarted.emit(data);
    });

    this.socket.on('getPublicGamesResponse', (data: SocketResponse<Game[]>) => {
      this.getPublicGamesResponse.emit(data);
    });

    this.socket.on('chooseQuestionResponse', (data: SocketResponse<any>) => {
      console.log(data);
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      this.selectQuestionRes.emit(data);

    });

    this.socket.on('chooserResponse', (data: SocketResponse<any>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      this.chooserResponse.emit(data.data);

    });

    this.socket.on('answerQuestionResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      this.answerQuestionRes.emit(data.data);

    });

    this.socket.on('nextRound', (data: SocketResponse<{ gameId: string, round: Round }>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      this.nextRound.emit(data.data);

    });

    this.socket.on('timeUp', (data: SocketResponse<{ gameId: string, mapping: Mapping }>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      this.timeUp.emit(data.data);

    });

    this.socket.on('gameOver', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      this.gameOver.emit(data.data);
    });

    this.socket.on('skippedUserResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      this.skippedUserResponse.emit(data.data);
    });

    this.socket.on('newGameResponse', (data: SocketResponse<Game>) => {
      // if (data.errorCode) {
      //   console.error(data.errorCode);
      //   return;
      // }
      this.newGameResponse.emit(data);
    });

    this.socket.on('joinGameResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        this.dataService.appLanguagesData.subscribe(res => {
          this.appLanguage = res;
        })
        console.error(data.errorCode);
        if (parseInt(data.errorCode) === 403) {
          this.snackBar.info(this.appLanguage?.Text_401);
          this.router.navigate(['/via-pin']);
        }
        return;
      }
      this.joinGameResponse.emit(data);
    });

    this.socket.on('nextQuestionResponse', (data: SocketResponse<Game>) => {
      console.log("nextQuestionResponse called in service");
      this.nextQuestionResponse.emit(data);
    });

    this.socket.on('myQuestionResponse', (data: SocketResponse<any>) => {
      console.log("myQuestionResponse called in service",data);
      this.game.next(data.data);
      this.myQuestionResponse.emit(data);
    });

    this.socket.on('removeFromLobbyResponse', (data: SocketResponse<{ gameId: string, playerId: string }>) => {
      this.removeFromLobbyResponse.emit(data);
    });

    this.socket.on('changeSettingsResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      this.changeSettingsResponse.emit(data.data);
    });

    this.socket.on('regenerateGamePinResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      console.log("regenerateGamePinResponse", data);
      this.regenerateGamePinResponse.emit(data.data);
    });

    this.socket.on('selectedOptionResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      console.log("selectedOptionResponse", data);
      this.selectedOptionResponse.emit(data.data);
    });

    this.socket.on('changeAvatarResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      console.log("changeAvatarResponse", data);
      this.changeAvatarResponse.emit(data.data);
    });

    this.socket.on('cancelGameResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      console.log("cancelGameResponse", data);
      this.cancelGameResponse.emit(data.data);
    });

    this.socket.on('connectedResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      console.log("connectedResponse", data);
      this.game.next(data.data);
      this.connectedResponse.emit(data.data);
      // window.location.reload();
    });

    this.socket.on('projectorModeResponse', (data: SocketResponse<Game>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      console.log("projectorModeResponse", data);
      this.projectorModeResponse.emit(data.data);
    });

    this.socket.on('gameChange', (data: SocketResponse<any>) => {
      if (data.errorCode) {
        console.error(data.errorCode);
        return;
      }
      console.log("gameChangeResponse", data);
      this.gameChangeResponse.emit(data.data);
    })
  }

  removeFromLobby(data: { gameId: string, playerId: string }) {
    this.socket.emit('removeFromLobby', data);
  }

  nextQuestion(data) {
    this.socket.emit('nextQuestion', data);
  }

  myQuestion(data) {
    this.socket.emit('myQuestion', data);
  }

  selectQuestion(data) {
    this.socket.emit('chooseQuestion', data);
  }

  selectOptions(data) {
    this.socket.emit('selectedOption', data);
  }

  answerQuestion(data) {
    console.log("answerQuestion");
    this.socket.emit('answerQuestion', data);
  }

  createGame(game: CreateGame, playerName: string, avatar: string) {
    this.isJoined = true;
    console.log("game", game);
    this.socket.emit('newGame', { playerName, game, avatar });
  }

  requestGames(data) {
    this.socket.emit('getPublicGames', data);
  }

  join(data) {
    console.log("join game req", data);
    this.socket.emit('joinGame', data);
  }

  startGame(data) {
    console.log(data)
    this.socket.emit('startGame', data);
  }

  changeSettings(data) {
    console.log(data)
    this.socket.emit('changeSettings', data);
  }

  regenerateGamePin(id) {
    console.log(id)
    this.socket.emit('regenerateGamePin', id);
  }

  changeAvatar(data) {
    console.log(data)
    this.socket.emit('changeAvatar', data);
  }

  cancelGame(data) {
    let data1 = { gameId: data }
    this.socket.emit('cancelGame', data1);
  }

  disconnect() {
    console.log('disconnect');
    this.manager.disconnect();
    this.socketConnected = false;
  }

  connectedSocket(gameId: any) {
    console.log("connect socket req", gameId);
    if (gameId) {
      let data = { gameId: gameId }
      this.socket.emit('connected', data);
    }
  }

  createSieve(sieve) {
    return this.http.post(`${$REST_URI}/game/common/presetSieve`, sieve);
  }

  saveSieve(sieve) {
    return this.http.put(`${$REST_URI}/game/common/presetSieve`, sieve);
  }

}